import { SetStateAction, useEffect, useState } from "react";
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import s from "./Rooms.module.scss";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../common/hooks/useTelegram";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { getRooms, selectRooms } from "./RoomsSlice";

export const Rooms = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { tg } = useTelegram();
  const rooms = useAppSelector(selectRooms);
  const [selectedRoom, setSelectedRoom] = useState("");

  const navigation = () => {
    nav("/services");
  };

  useEffect(() => {
    tg.BackButton.hide();
    tg.MainButton.setText("ВЫБРАТЬ SERVICE");
    if (selectedRoom && selectedRoom !== "Rooms") {
      tg.MainButton.show();
      tg.MainButton.onClick(() => {
        navigation();
      });
    } else {
      tg.MainButton.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom, tg]);

  useEffect(() => {
    dispatch(getRooms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rooms && rooms?.length > 0) {
      setSelectedRoom(rooms[0]?.name);
    }
  }, [rooms]);

  const handleItemClick = (item: SetStateAction<string>) => {
    setSelectedRoom(item);
  };

  const getSelectedRoomDescription = () => {
    const selected = rooms?.find(room => room.name === selectedRoom);
    return selected ? selected.description : "";
  };

  return (
    <Flex className={s.container} align="center">
      <Flex align="center" w="100%" gap="20px" flexDir="column">
        <Text className={s.title}>ВЫБЕРИТЕ ROOM</Text>
      </Flex>
      <Menu matchWidth>
        <MenuButton w={"100%"} as={Button} rightIcon={<ChevronDownIcon />}>
          {selectedRoom}
        </MenuButton>
        <MenuList display={"flex"} flexDir={"column"} w={"100%"}>
          {rooms?.map(room => (
            <MenuItem key={room?.id} onClick={() => handleItemClick(room?.name)}>
              {room?.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Text className={s.description}>{getSelectedRoomDescription()}</Text>
    </Flex>
  );
};
