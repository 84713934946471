import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { Provider } from "react-redux";
import { createStore } from "./common/state/store";
import "./common/i18next/i18n.ts";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={createStore()}>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </Provider>,
);
