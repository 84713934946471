import { call, put, takeLatest } from "redux-saga/effects";
import * as AvailableDatesApi from "./AvailableDatesApi";
import { handleException } from "../../../../common/SagaHelper";
import { AvailableDatesSlice } from "./AvailableDatesSlice";
import { AvailableDatesData } from "./AvailableDatesData";
import { AvailableDatesModel } from "./AvailableDatesModel";
import { PayloadAction } from "@reduxjs/toolkit";

export function* AvailableDatesSaga() {
  yield takeLatest(AvailableDatesSlice.actions.getAvailableDates, getAvailableDates);
}

export function* getAvailableDates(action: PayloadAction<{ roomId: string | null; serviceId: string | null }>) {
  try {
    const data: AvailableDatesData[] = yield call(
      AvailableDatesApi.getAvailableDates,
      action?.payload?.roomId,
      action?.payload?.serviceId,
    );
    const model: AvailableDatesModel[] = [...data];
    if (model.length > 0) {
      yield put(AvailableDatesSlice.actions.getAvailableDatesCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}
