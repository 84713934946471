import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/state/store";
import { AvailableDatesState } from "./AvailableDatesState";
import { AvailableDatesModel } from "./AvailableDatesModel";

const initialState: AvailableDatesState = {
  availableDates: [],
};

export const AvailableDatesSlice = createSlice({
  name: "availableDatesSlice",
  initialState,
  reducers: {
    getAvailableDates: (state, action: PayloadAction<{ roomId: string | null; serviceId: string | null }>) => {
      return {
        ...state,
      };
    },
    getAvailableDatesCompleted: (state, action: PayloadAction<AvailableDatesModel[]>) => {
      return {
        ...state,
        availableDates: action.payload,
      };
    },
  },
});

export const { getAvailableDates, getAvailableDatesCompleted } = AvailableDatesSlice.actions;

export const selectAvailableDates = (state: RootState) => state.availableDatesState.availableDates;

export default AvailableDatesSlice.reducer;
