import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../common/hooks/useTelegram";

export const Confirmation = () => {
  const { tg } = useTelegram();
  const nav = useNavigate();

  useEffect(() => {
    tg.MainButton.setText("ПОДТВЕРДИТЬ");
    tg.MainButton.show();
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      nav("/timeSlots");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tg]);

  return <Flex>Confirm</Flex>;
};
