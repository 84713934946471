import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ServicesModel } from "./ServicesModel";
import { ServicesState } from "./ServicesState";

const initialState: ServicesState = {
  services: [],
};

export const ServicesSlice = createSlice({
  name: "servicesSlice",
  initialState,
  reducers: {
    getServices: state => {
      return {
        ...state,
      };
    },
    getServicesCompleted: (state, action: PayloadAction<ServicesModel[]>) => {
      return {
        ...state,
        services: action.payload,
      };
    },
  },
});

export const { getServices, getServicesCompleted } = ServicesSlice.actions;

export const selectServices = (state: RootState) => state.servicesState.services;

export default ServicesSlice.reducer;
