import { Flex, Button, ButtonGroup } from "@chakra-ui/react";
import s from "./TimeSlots.module.scss";
import { useTelegram } from "../../../../common/hooks/useTelegram";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { getAvailableDates, selectAvailableDates } from "../../../Schedule/component/AvailableDates/AvailableDatesSlice";
import { selectRooms } from "../../../Rooms/RoomsSlice";
import { selectServices } from "../../../Services/ServicesSlice";

export const TimeSlots = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(selectAvailableDates);
  const room = useAppSelector(selectRooms);
  const service = useAppSelector(selectServices);
  const { tg } = useTelegram();
  const nav = useNavigate();
  console.log(room, service);

  useEffect(() => {
    dispatch(getAvailableDates({ roomId: "", serviceId: "" }));

    tg.MainButton.setText("ПРОВЕРИТЬ ЗАПИСЬ");
    tg.MainButton.show();
    tg.MainButton.onClick(() => {
      nav("/confirmation");
    });
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      nav("/services");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tg]);

  return (
    <Flex className={s.container} flexDirection="column">
      <Flex overflowX="auto" className={s.dateScrollContainer}>
        <ButtonGroup>{dates?.map((date, index) => <Button key={index}>{String(date)}</Button>)}</ButtonGroup>
      </Flex>
    </Flex>
  );
};
