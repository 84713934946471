import { SetStateAction, useEffect, useState } from "react";
import { Button, Flex, Text, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import s from "./Services.module.scss";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../common/hooks/useTelegram";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { getServices, selectServices } from "./ServicesSlice";

export const Services = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { tg } = useTelegram();
  const [selectedService, setSelectedService] = useState("");
  const services = useAppSelector(selectServices);

  const navigation = () => {
    nav("/timeSlots");
  };

  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      nav("/");
    });
    tg.MainButton.setText("ВЫБРАТЬ TIME SLOT");
    if (selectedService && selectedService !== "Services") {
      tg.MainButton.show();
      tg.MainButton.onClick(() => {
        navigation();
      });
    } else {
      tg.MainButton.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, tg]);

  useEffect(() => {
    dispatch(getServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services && services?.length > 0) {
      setSelectedService(services[0]?.name);
    }
  }, [services]);

  const handleItemClick = (item: SetStateAction<string>) => {
    setSelectedService(item);
  };

  const getSelectedServiceDescription = () => {
    const selected = services?.find(service => service.name === selectedService);
    return selected ? selected.id : "";
  };

  return (
    <Flex className={s.container} align="center">
      <Flex align="center" w="100%" gap="20px" flexDir="column">
        <Text className={s.title}>ВЫБЕРИТЕ ROOM</Text>
      </Flex>
      <Menu matchWidth>
        <MenuButton w={"100%"} as={Button} rightIcon={<ChevronDownIcon />}>
          {selectedService}
        </MenuButton>
        <MenuList display={"flex"} flexDir={"column"} w={"100%"}>
          {services?.map(service => (
            <MenuItem key={service?.id} onClick={() => handleItemClick(service?.name)}>
              {service?.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Text className={s.description}>{getSelectedServiceDescription()}</Text>
    </Flex>
  );
};
