import { useEffect } from "react";
import { useTelegram } from "./common/hooks/useTelegram";
import { getURLSearchParams } from "./common/utils/getURLSearchParams";
import { createBrowserRouter, createRoutesFromElements, Route, Routes, RouterProvider } from "react-router-dom";
import { Rooms } from "./components/Rooms/Rooms";
import { Services } from "./components/Services/Services";
import { Confirmation } from "./components/Confirmation/Confirmation";
import { TimeSlots } from "./components/Schedule/component/TimeSlots/TimeSlots";

export const App = () => {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  }, [tg]);

  useEffect(() => {
    getURLSearchParams();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/*"
          element={
            <Routes>
              <Route path="/" element={<Rooms />} />
              <Route path="/services" element={<Services />} />
              <Route path="/timeSlots" element={<TimeSlots />} />
              <Route path="/confirmation" element={<Confirmation />} />
            </Routes>
          }
        ></Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};
