import { call, put, takeLatest } from "redux-saga/effects";
import * as RoomsApi from "./RoomsApi";
import { handleException } from "../../common/SagaHelper";
import { RoomsSlice } from "./RoomsSlice";
import { RoomsData } from "./RoomsData";
import { RoomsModel } from "./RoomsModel";

export function* RoomsSaga() {
  yield takeLatest(RoomsSlice.actions.getRooms, getRooms);
}

export function* getRooms() {
  try {
    const data: RoomsData[] = yield call(RoomsApi.getRooms);
    const model: RoomsModel[] = [...data];
    if (model.length > 0) {
      yield put(RoomsSlice.actions.getRoomsCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}
