import { Action, combineReducers } from "@reduxjs/toolkit";
import { ApplicationReducerState, ApplicationState } from "./ApplicationState";
import NotificationSlice from "../notifications/NotificationSlice";
import RoomsSlice from "../../components/Rooms/RoomsSlice";
import ServicesSlice from "../../components/Services/ServicesSlice";
import AvailableDatesSlice from "../../components/Schedule/component/AvailableDates/AvailableDatesSlice";

export const rootReducer = (state: ApplicationState | undefined, action: Action) => {
  const combinedReducers = combineReducers<ApplicationReducerState>({
    notificationState: NotificationSlice,
    roomsState: RoomsSlice,
    servicesState: ServicesSlice,
    availableDatesState: AvailableDatesSlice,
  });
  return combinedReducers(state, action);
};
