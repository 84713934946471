import { delay } from "redux-saga/effects";
import { ApiError, BadRequestError, Error400Model, ErrorNoContent, NotFoundRequestError } from "./ErrorModel";

let isExpired = true;

export function* httpGet(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", resourceUrl);
  return result;
}

export function* httpPost<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", resourceUrl, data, undefined);
  return result;
}

export function* httpPut<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("PUT", resourceUrl, data);
  return result;
}

export function* httpDelete(resourceUrl: string): unknown {
  const result = yield fetchBase("DELETE", resourceUrl);
  return result;
}

export const getOrganisationIdFromStorage = () => {
  const orgId: string | null = sessionStorage.getItem("orgId");
  return orgId || "org";
};

export const getLngFromStorage = () => {
  const lng: string | null = sessionStorage.getItem("lng");
  return lng || "";
};

function* fetchBase(method: string, resourceUrl: string, data: unknown = null, retry = false): unknown {
  const params = new URLSearchParams(window.location.search);
  const orgId = getOrganisationIdFromStorage() || params.get("orgId") || "";
  const lng = getLngFromStorage() || params.get("lng") || "";
  const result = yield fetch(`${resourceUrl}`, {
    method: method,
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": `application/vnd.domino.v1+json`,
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-organization": orgId,
      "x-domino-lang": lng,
    },
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });

  return yield handleResponse(result, method, resourceUrl, retry, data);
}

function* handleResponse(
  response: Response,
  method: string,
  resourceUrl: string,
  retry?: boolean,
  data: unknown = null,
): unknown {
  if (response?.status === 401 && !retry) {
    isExpired = !isExpired;
    response = yield fetchBase(method, resourceUrl, data, true);
  }

  if (response?.status === 200) {
    isExpired = false;
    if (retry) {
      return response;
    }
    const textResponse = yield response.text();
    try {
      return JSON.parse(textResponse);
    } catch {
      return textResponse;
    }
  }

  if (response?.status === 400) {
    const data: Error400Model = yield response.json();
    throw new BadRequestError(data?.uiMessage || data?.message || "Bad Request Error");
  }

  if (response?.status === 204) {
    yield delay(1000);
    throw new ErrorNoContent("No Data");
  }

  if (response?.status === 404) {
    throw new NotFoundRequestError();
  }

  if (response?.status === 500) {
    throw new ApiError("Server Error");
  }
}
