import { call, put, takeLatest } from "redux-saga/effects";
import * as ServicesApi from "./ServicesApi";
import { handleException } from "../../common/SagaHelper";
import { ServicesSlice } from "./ServicesSlice";
import { ServicesData } from "./ServicesData";
import { ServicesModel } from "./ServicesModel";

export function* ServicesSaga() {
  yield takeLatest(ServicesSlice.actions.getServices, getServices);
}

export function* getServices() {
  try {
    const data: ServicesData[] = yield call(ServicesApi.getServices);
    const model: ServicesModel[] = [...data];
    if (model.length > 0) {
      yield put(ServicesSlice.actions.getServicesCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}
