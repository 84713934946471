import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { RoomsModel } from "./RoomsModel";
import { RoomsState } from "./RoomsState";

const initialState: RoomsState = {
  rooms: [],
};

export const RoomsSlice = createSlice({
  name: "roomsSlice",
  initialState,
  reducers: {
    getRooms: state => {
      return {
        ...state,
      };
    },
    getRoomsCompleted: (state, action: PayloadAction<RoomsModel[]>) => {
      return {
        ...state,
        rooms: action.payload,
      };
    },
  },
});

export const { getRooms, getRoomsCompleted } = RoomsSlice.actions;

export const selectRooms = (state: RootState) => state.roomsState.rooms;

export default RoomsSlice.reducer;
