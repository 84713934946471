import { fork } from "redux-saga/effects";
import { RoomsSaga } from "../../components/Rooms/RoomsSaga";
import { ServicesSaga } from "../../components/Services/ServicesSaga";
import { AvailableDatesSaga } from "../../components/Schedule/component/AvailableDates/AvailableDatesSaga";

export function* rootSaga() {
  yield fork(RoomsSaga);
  yield fork(ServicesSaga);
  yield fork(AvailableDatesSaga);
}
